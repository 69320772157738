import React from 'react'
import classnames from 'classnames'

const PageHeading = ( props ) => (
	<div className={classnames('page-heading', props.className)}>
	    <div className="container">
	        <h1 className="page-heading__title">{ props.text } </h1>
	        <div className="page-heading__text">
	        	{ props.children }
	        </div>
	    </div>
	</div>
)

export default PageHeading